exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-portfolio-blueprint-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/portfolio/blueprint/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-portfolio-blueprint-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-portfolio-celestial-seasonings-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/portfolio/celestial-seasonings/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-portfolio-celestial-seasonings-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-portfolio-contests-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/portfolio/contests/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-portfolio-contests-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-portfolio-coolbrew-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/portfolio/coolbrew/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-portfolio-coolbrew-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-portfolio-gluten-free-choices-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/portfolio/gluten-free-choices/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-portfolio-gluten-free-choices-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-portfolio-hcg-products-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/portfolio/hcg-products/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-portfolio-hcg-products-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-portfolio-hcgpublic-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/portfolio/hcgpublic/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-portfolio-hcgpublic-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-portfolio-hcgweb-portal-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/portfolio/hcgweb-portal/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-portfolio-hcgweb-portal-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-portfolio-portfolio-website-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/portfolio/portfolio-website/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-portfolio-portfolio-website-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-portfolio-quiptionary-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/portfolio/quiptionary/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-portfolio-quiptionary-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-portfolio-recipes-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/portfolio/recipes/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-portfolio-recipes-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-portfolio-rtags-and-apis-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/portfolio/rtags-and-apis/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-portfolio-rtags-and-apis-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-portfolio-spectrum-ingredients-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/portfolio/spectrum-ingredients/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-portfolio-spectrum-ingredients-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-portfolio-wtw-patterns-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/portfolio/wtw-patterns/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-portfolio-wtw-patterns-index-mdx" */)
}

